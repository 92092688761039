import React, { useState, useEffect } from "react"
import {Button, Col, Form} from 'react-bootstrap'

const SignEmail = ({signApi, onMessage}) => {

  const [submiting, Submiting] = useState(false); 
  const [starting, setStart] = useState('Start'); 
  const [valid, setValid] = useState(false);
  const [mode, Mode] = useState(signApi.data.mode);

  useEffect(() => {
    if(valid) {
      signApi.isDataReady(1)
      if(mode===2) {
        onSubmit()
      }
    }
  }, [valid])  

  useEffect(() => {
    if(mode===1) {
      if(signApi.isDataReady(1)) { // from Cockie
        const element = document.getElementById('Email')
        if(element){
          if(signApi.isEmailReady(element.value)) {
            element.focus()
            setValid(true)
            setStart('Next')
          }
          //element.dispatchEvent(new Event('change')) //; alert(`Cockie ${element.value}`)
        }
      }
    }
  }, [mode])  

  function onSubmit(event) {
    Submiting(true)
    const cookie = `meEmail=${signApi.data.email}` //;alert(cookie)
    document.cookie = cookie

      signApi.POST(Mode)
      .then(()=>{
        if(signApi.data.mode>0) {
          onMessage && onMessage(signApi.message)
          Submiting(false)
          setValid(false)
        }
      })
  }

  const onKey = (e) => {
    if(e.key==='Enter') {
      e.preventDefault()
      if(valid) {
        onSubmit(e)
      }
    }
    //else if(e.key==='Escape') e.preventDefault()
  }

  const onChange = (e) => {
    setValid(false)
    setStart('Invalid Email')
    //alert(`onChange ${e.target.value}`)
    if(signApi.isEmailReady(e.target.value)) {
          setValid(true)
          setStart('Next')
    }
  }

const onChangeCode = (e) => {
  signApi.data.code=e.target.value

  setValid(false)
  setStart('Invalid Code')
  if(signApi.data.code.length>4) {
        setValid(true)
        setStart('Sign In')
  }  
}


  return (
    <div className="outlet">
      
      { signApi.data.mode<3 ?
      <Form style={{width:"100%"}}>
           
           <Form.Group style={{justifyContent:"center", display: "flex", alignItems:"center"}}>  
                      
           { signApi.data.mode===1 ?
           <Col sm={8} >
               <Form.Control key={"SignEmail"} id="Email" className="SignInput"
               type="email"
               name="Email"
               required
               placeholder="Email"
               onKeyDown={onKey}
               defaultValue={signApi.data.email}
               onChange={onChange}
               />
              </Col>
              :
              <Form.Control key={"SignCode"}  className="SignInput"
              style={{width:"50%", color:"darkred"}}
              name="Code"
              required
              placeholder="Code5"
              onKeyDown={onKey}
              defaultValue=""
              onChange={onChangeCode}
              />
           }
           </Form.Group>

           <Form.Group style={{justifyContent:"center", alignItems:"center", display: "flex", padding:"1rem"}}>

           <Button onClick={() => onSubmit()} size="lg" 
              style={ valid  && !submiting 
                ? {width:"auto", padding:'0.5rem 1rem', color:'white'} 
                : {width:"auto", padding:'0.5rem 1rem', backgroundColor:'darkgray', color:'gray', pointerEvents:'none', cursor:'not-allowed'}}
            >{starting}</Button>

           </Form.Group>
      </Form>
      : 
      null
      }
    </div>

  )
}
export default SignEmail