import {base} from '../app'

export const arConfig = {
    ...base,
    bismi:'بِسۡمِ ٱللهِ ٱلرَّحۡمَـٰنِ ٱلرَّحِيمِ',
    salaam:'ٱلسَّلَامُ عَلَيْكُمْ وَرَحْمَةُ ٱللهِ وَبَرَكَاتُهُ',
    title :'Moshaf Akademy',
    logo : 'logo.png',

home : {
    login:'الدخول',
    about:'خدمة المصحف لقراءة وتعلم وحفظ القرآن الكريم في كل وقت وفي كل مكان',
    article : 'المقالات',
    contact : 'الاتصال',
},

blog: {
    read:'اقرأ المزيد...',
    title :'أكثر المقالات إثارة للاهتمام',
},

profile : {
    profile : 'الصفحة الشخصية',
    audio : 'الصوت',
    akademy : 'أكاديمي',
    akademyApp : 'تطبيق أكاديمي',
    setting : 'إعدادات',
},

contact:{
    title:'تواصل معنا',
    quote:'تأكيد الاتصال',
    message:'يرجى ملء النموذج أدناه لإرسال بريد إلكتروني إلينا',
    confirm:'تأكيد معلومات الاتصال',
    content:'يرجى ملء الكود الذي أرسلناه لك عبر البريد الإلكتروني لتأكيده',
    recipient:"يرجى تأكيد معلومات المستلم.",
    feedback : 'لقد تلقينا رسالتك، وسنقوم بالرد عليك في أقرب وقت ممكن',
    warning : 'يجب عليك تأكيد بريدك الإلكتروني قبل المتابعة',
},
}