// Hero.js (example)
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {Ts} from '../Config/switcher';

const About = ({lang}) => {

  useEffect(() => {
//    const trigger = useScrollTrigger({ onEnter: () => setIsScrolled(true), onLeave: () => setIsScrolled(false) });
//    return () => trigger.cleanup();
  }, []);

  return (
    <AboutContainer id="about">
      <h1 className='brillant'>{Ts(lang,'home','about')}</h1>
    </AboutContainer>
  );
};


const AboutContainer = styled.div`
  max-width: 1024px;
  margin: auto;
  background-image: url('https://moshaf.akademy.ma/images/about.png');
  background-size: cover; /* contain; */
  background-position: center;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  align-items: center;
  color: #fff;
`;

export default About;

