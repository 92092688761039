import {NavLink} from 'react-router'
import {Navbar, Container} from 'react-bootstrap'

export const Navigation = ({connected}) => {

    //alert("Navigation "+connected)
        return (
            
connected &&
        <>
            <NavLink className="d-inline p-2 navbar-custom" to="https://moshaf.akademy.ma/iqrae" target="_blank" rel="noopener noreferrer">ٱقۡرَأۡ</NavLink>
            <NavLink className="d-inline p-2 navbar-custom" to="/page">تدبر</NavLink>
            <NavLink className="d-inline p-2 navbar-custom" to="/sentence">إحفظ</NavLink>
        </>
        )
}
