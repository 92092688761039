// App.js
import React from 'react';
import './Landing.css';
import Articles from '../Article/Articles';
import About from './About';
import Slider from '../Article/Slider';
import Contact from './Contact';
import {Services} from '../Service/Services';

function Home({search, lang="ar", account, Etat}) {
  return (
    account?.connected &&
    <div className="row Home">
      <Slider   service={account.service} lang={lang} />
    <Services account={account} lang={lang} domain='1' />
    <Articles account={account} lang={lang} />
    <About lang={lang} />
    </div>
  );
}

export default Home;

/*
<Slider   service={service} lang={lang} />
<Services service={service} lang={lang} domain=1 />
<Articles service={service} lang={lang} />
<Contact  service={service} lang={lang} />
<About    service={service} lang={lang} />

*/