import React, {useState, useEffect} from "react"
import {Modal,Button,Row} from 'react-bootstrap'
import './Sign.css'
import SignEmail from "./SignEmail"
import SignPhone from "./SignPhone"
import SignApi from './SignApi'
import {ToNotify} from 'fast_inon'

const signApi = new SignApi()

const SignModal = ({service, show, logo, onHide}) => {
  const [message, Message] = useState('')
  const [tab, Tab] = useState("Email")
  const [code, Code] = useState('')

    const onEmail = () => {
        Tab("Email")
    }

    const onPhone = () => {
      Tab("Phone")
    }

    useEffect(() => {
      signApi.setData(service)
    }, [service])

    useEffect(() => {
      Code(signApi.message)
      if(signApi.data.mode===3) {
        window.location.reload()
      } 
    }, [signApi.data.mode])

    const onClose = () => {
      onHide()
      if(signApi.data.mode===3) {
        window.location.reload()
      }      
    }


  return (
    <div className="mt-5 d-flex justify-content-left">

    <Modal show={show}
            onHide={onClose}
                size="lg"
                animation={false}
                centered
            >
          
        <Modal.Header closeButton>
            <Row  style={{ justifyContent:"center", width:"100%"}}>
                <h3 style={{color:"darkblue", margin:"0rem"}}>Sign In {service} </h3>
            </Row>          
        </Modal.Header>

        <Modal.Body>
          <Row style={{ justifyContent:"center", width:"100%"}}>
              <img  src={logo} alt="Home" style={{height:'12rem', width:'12rem', padding:0}} />
          </Row>
  
    <div className="Tabs">
      { signApi.data.mode===1 ?
      <ul className="nav">
        <li className={tab === "Email" ? "active" : ""} onClick={onEmail}>Email</li>
        <li className={tab === "Phone" ? "active" : ""} onClick={onPhone}>Phone</li>
      </ul>
      : 
      <h3 style={{color:"lightblue", margin:"2rem"}}>{code}</h3> 
      }
      
      { tab === "Email" ? <SignEmail signApi={signApi} onMessage={Message}/> 
                              : <SignPhone signApi={signApi} onMessage={Message}/> }
    </div>

    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-danger" onClick={onClose}><i className="fa fa-close"></i> Close</Button>
    </Modal.Footer>
  </Modal>
 
    <ToNotify 
      message={message}
      onClose={()=>{Message('')}}
    />    

    </div>

  )
}
export default SignModal