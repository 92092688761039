import React,{useState, useEffect} from 'react'
import {Button} from 'react-bootstrap'
import './meImage.css'
import Api, {Read, Fetch} from '../Shared/Api'
import {meChoice} from '../Shared/meGlobal'

export const AreaView = ({node, onNode, Message=null}) => {
    //alert(message)
    const [loaded, Load] = useState(false)
    const [items, Items]= useState([])
    const [clicked, Clicked] = useState({n:0,x:0,y:0,now:new Date()})
    const [areas, Areas]= useState([])
    const [page,Page] = useState({title:'',src:'', height:0, lines:1})

    const clipper = new meChoice('Area')
    clipper.historizing = 'akademyma'

    useEffect(() => {
        Load(false)
    }, [node])

    useEffect(() => {

        if(!loaded) {
            //cropImage(page.path, 250, 250, 500, 500)
            if(node.id>0) {
                const url = `moshaf/${node.entity}/${node.id}` //;alert(`Image Get ${url}`)
                Read(url, Items, Load)   
            }             
        }

        //onRefresh()

        if(items?.length>0) {
            Page({            
                src:items[0].Path, //.replace('./','https://api.tech2us.ma/'),
                title:items[0].Name,
                lines:items[0].Lines,
                height:items[0].Height
            })
        }
    }, [loaded])


    const parseArea = (coord, number) => {
        const zones = []
        const cs = coord?.split(',')   // x,y,w,h
        if(cs.length===4) {
                //alert(coord)
            //let h = 100/page.lines //  page.height/page.lines
            //let zs = cs[2]/h
            //alert(`parseArea ${page.lines} ${h} ${cs[2]} ${zs}`)

            zones.push(clipper.toPx(cs))

            return zones
        }
        else {
            //alert(`parseArea coord ${coord} number ${number}`)
            const zone = clipper.toPx([10,number*9,50,6])
            //console.log('zone', zone)
            zones.push(zone)
            return zones
        }
    }

    const onRefresh = (e) => {
        Areas([])

        clipper.setArea(`${node.entity}`,`${node.entity}Image`)
        // Get Clips
        var data = []
        Api.get(`moshaf/Area/${node.id}`)
        .then(response => {
            response.data.forEach(item => {
                const zones = parseArea(item.Coord, item.Number)

                data.push({...item, Zones:zones})
                //console.log(clip.Coord, position)
            })
            Areas(data)
            
            //Message(`${data.length} Clips are loaded !`)
        })
    }

    const onClick = (e) => {
        const cr = e.currentTarget.getBoundingClientRect()
        //console.log(cr,e) ;alert(`onClick (event x ${e.clientX}, y ${e.clientY})`)
        if(cr && cr.right>0 && cr.bottom>0) {
            let w=parseInt(cr.right-cr.left)
            let h=parseInt(cr.bottom-cr.top)
           
            let n = clicked.n
            let x = parseFloat((e.clientX-cr.left)*100/w)?.toFixed(2)||0
            let y = parseFloat((e.clientY-cr.top )*100/h)?.toFixed(2)||0
            const now = new Date()
    
            if(Math.abs(clicked.x-x)<3 && Math.abs(clicked.y-y)<3) {
                let ms=now.getTime()-clicked.now.getTime()
                if(ms<500) {
                    n=n+1
                    if(n>1) {  // 3 click !

                        //Fetch clicked
                        const data = {
                            Entity: clipper.category,
                            ParentId: node.id,
                            Coord : `${clicked.x},${clicked.y},20,5`
                        }
                        //alert(data.Coord)
                        Fetch('data', data, e.currentTarget)
                        .then(item => {
                            onNode && onNode({...node, parent:node.parent})
                        })
                        .catch(err=>{
                            console.log(err)
                            //alert('Add Clip ?')
                        })
        
                    }
                }
                else
                    n=0
            }
            else
                n=0
    
            Clicked({n,x,y,now})  
        }
    }

    const onMouseDown = (e) => {
        clipper.setArea(`${node.entity}`,`${node.entity}Image`)
        clipper.mouseDown(e)
    }

    const onMouseMove = (e) => {
        clipper.mouseMove(e)
    }

    const onMouseUp = (e) => {
        clipper.mouseUp(e)
    }


    return (

items.length>0 ?
    <div className="Page" id={`${node.entity}`}>

        <img alt="Wajh" id={`${node.entity}Image`} 
            src={page.src} 
            title={page.title}
            onClick={onClick}
        /> 
{ areas?.length>0 && 
    areas.map((area, i) => (
        
        <button className="Clip" id={`${clipper.category}${area.Id}`} 
            style={{...area.Zones[0], 
                backgroundColor : `rgba(${area.Back})`,
                borderColor : `rgba(${area.Bord})`
            }} 
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}        
        >

        {
        area.Zones.map((zone, z) => (

            <button className="Zone" id={`Zone-${area.Id}-${z}`} style={{...zone}} 
            >{z}</button>   
            
        ))
        }
        </button>
    ))
}

    <Button className='Icon' variant={"danger"}  
        style={{ top:0, left:0, width:'4rem' }}
        onClick={(e)=>onRefresh(e)}
        ><i className={"fa fa-expand"} style={{display: 'flex', justifyContent: 'center'}}>Refresh</i>
    </Button>    

</div>
:
    <h2>Cropping & Resizing Images</h2>
    )
}
