import React, { useState, useEffect } from 'react'
import TableView from '../meTable/TableView'
import {ToNotify} from 'fast_inon'

const Profile = ({connected}) => {
    const table = {name:'api_key', id:1, parent:1}
    const [message, Message] = useState('')

    const onCell = (item) => {
        //alert(`entity=${item.Value}`)
    }

return (

<div className="Home" id="MainContainer">

{ connected ?  
  <div className='CollapseTab'> 
    <TableView request={'member'} entity={table} onChange={onCell} />
  </div>
: null }

<ToNotify 
    message={message}
    onClose={()=>{Message('')}}
/>

</div>
        )
}

export default Profile
