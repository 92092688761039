import React, {useState, useRef, useEffect} from 'react'
import {Route, Routes, BrowserRouter} from 'react-router';
import './App.css';
import {base} from './components/Config/app'
import Home from './components/Home/Home';
import Header from './components/Home/Header';
import Footer from './components/Home/Footer';
import {Player} from './components/Shared/ViewAudio';
import Settings from './components/Setting/Settings';
import Profile from './components/Profile/Profile';
import Sentence from './components/Sentence/Sentence';
import Pages from './components/Page/Pages';

//import Sentences from './components/Sentence/Sentences';

function App() {
  const app = "Akademy"
  const logo = 'logo.png'
  const url ="https://moshaf.akademy.ma/waqafaat/001.mp3"

  const route = useRef("/")

  const [search, Search]  = useState("")
  const [etat, Etat]  = useState("")
  const [account, Account] = useState({connected:false, message:'',roles:'0', service:base.service})
  const [layout, Layout] = useState(window.matchMedia('(orientation:landscape)').matches)

  useEffect(() => {
      const store = localStorage.getItem(app)
      if(store) {
        Account({...JSON.parse(store), service:base.service})
      }
  },[])

  useEffect(() => {
    //console.info('localStorage',app,account); //alert('localStorage')
  },[account])


  return (

    <BrowserRouter>

    <Header app={app} onSearch={Search} logo={logo} account={account} />

{ account?.connected ?
  <Routes>    
      <Route path='/' element={ <Home search={search} account={account} Etat={Etat} />} exact />
      <Route path='/sentence' element={ <Sentence props={{connected:account.connected, search, layout}} />} />
      <Route path='/page' element={ <Pages search={search} connected={account.connected} Etat={Etat} />} />
      <Route path='/setting' element={ <Settings account={account} search={search} layout={layout}/>} />
      <Route path='/profile' element={ <Profile connected={account.connected} />} />
      <Route path='/audio' element={ <Player connected={account.connected} />} />
  </Routes>
:      
  <Home search={search} connected={account.connected} Etat={Etat} />
}
    <Footer etat={etat} connected={account.connected} />

    </BrowserRouter>

  )

}

export default App;
