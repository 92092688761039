import React, {useState, useEffect} from 'react'
import './Service.css'
import {Get} from '../Shared/Api'

const Services = ({domain, account, lang}) => {

    const [loaded, Load] = useState(false)
    const [items , Items] = useState([])
  
    useEffect(()=>{        
  
      if(domain>0) {
        Get(`/service/${domain}`, Items, Load)
        //console.log(items)
      }
  
  },[domain])
  
    return (
        <div className='Services' >

        {loaded && items.map((item) => (
            <Service name={item.Name} 
            icon={item.Icon} 
            info={item.Info}
            plan={item.Plan}
            start={item.Start}
            to={item.Link}
          />
        ))}

</div>
    )

}

const Service = ({name, icon, info, plan, start, to}) => {

    //alert(info)

    return (
        <div className='Service'>
            <div className="ServiceIcon">
                <a target="_blank" rel="noopener noreferrer" href={to}>
                    <img src={icon.startsWith('https') ? icon : to+icon} alt={name+": "+info} />
                </a>
            </div>
            <div className="ServiceName">
                <a target="_blank" rel="noopener noreferrer" href={to}>
                    <h1 style={{fontWeight: 'bold'}}>{name}</h1>
                </a>
                {info && <h4 className="ServiceInfo">{info}</h4>}
                {plan && <h3 className="ServiceInfo">{plan}</h3>}
            </div>
        </div>
    )
}

export {Service, Services}

